import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from '../../components/Loader'
import { datalayerPushGTM, datalayerPush } from 'Helpers/application'

import RegistrationBreadcrumb from '../../Authentication/Registration/components/RegistrationBreadcrumb'

import { amountWithoutTax, clearPreviousEcommerceObject, gaUserTags } from '../../../../libs/google_analytics_helpers'
import SvgImage from 'Components/SvgImage'
import LogoSvg from 'ClientAssets/images/onepark-header-logo.svg'
import { CommonSummary } from '../CommonSummary'

class Summary extends CommonSummary {
  duplicateBookingModalRef = React.createRef()

  static storagePrefix = (props) => props.offerBuilder.id

  pushGaEvent () {
    try {
      const { offerBuilder } = this.props
      const park = offerBuilder.park || {}
      const amounts = offerBuilder.amount || {}
      const vatPercent = parseFloat(park.vat)
      const optionPrice = offerBuilder.multipleEntries && this.state.isMultipleEntriesOption
        ? parseFloat(offerBuilder.multipleEntries.optionPrice)
        : 0.0
      const value = amounts.totalWithoutOptions
        ? amountWithoutTax(parseFloat(amounts.totalWithoutOptions + optionPrice), vatPercent)
        : undefined
      const serviceFee = amounts.serviceFee ? amountWithoutTax(amounts.serviceFee, vatPercent) : undefined
      const price = amounts.bookingAmount
        ? amountWithoutTax(parseFloat(amounts.bookingAmount) + optionPrice, vatPercent)
        : undefined
      const place = amounts.place || {}
      const categories = place && place.categories ? place.categories : []
      const placeId = place.id ? place.id.toString() : undefined
      const gaEvent = {
        event: 'begin_checkout',
        ...gaUserTags(),
        ecommerce: {
          item_list_id: placeId,
          item_list_name: place.name,
          currency: 'EUR',
          value,
          sf_pa: serviceFee,
          payment_type: 'credit_card',
          items: [{
            item_id: park.id.toString(),
            item_name: park.seoName,
            item_type: offerBuilder.offerType,
            item_brand: 'onepark',
            item_variant: park.covered,
            price,
            quantity: 1,
            quantity_hours: amounts.gaQuantity,
            item_category: categories[0],
            item_category2: categories[1],
            item_category3: categories[2],
            item_category4: categories[3],
            item_list_id: placeId,
            item_list_name: place.name
          }]
        }
      }
      clearPreviousEcommerceObject()
      window.dataLayer.push(gaEvent)
    } catch (error) {
      if (!(error instanceof TypeError)) { // Network errors
        Sentry.captureException(error)
      }
    }
  }

  duplicateBookingUrl = () => {
    const { localePrefix, offerBuilder: { park: { id: parkId } }, travelInfos: { license_plate: licensePlate } } = this.props
    return `${localePrefix}/bookings/check_duplicated?park_id=${parkId}&license_plate=${licensePlate}`
  }

  componentDidMountComponentSpecific = () => {
    datalayerPush({ event: 'page_name', page_name: 'purchase_summary' })
    datalayerPush({
      event: 'page_view',
      ep_page_type_level1: 'booking_summary_and_payment',
      ep_page_type: 'ecommerce',
      ep_site: window._oneparkDatas.ep_site,
      ep_page_name: document.getElementsByTagName('title')[0].innerText,
      ep_logged_status: window._oneparkDatas.user_signed_in,
      up_user_id: window._oneparkDatas.up_user_id,
      up_language: window._oneparkDatas.up_language,
      up_locale: window._oneparkDatas.up_locale
    })
    datalayerPushGTM('hitVirtualPageView')
    this.pushGaEvent()
  }

  // is used for completeBooking: which data to submit on the main CTA button
  backendPostParams = () => {
    const { userConsent, offerBuilder, beginDate, endDate, renewalDate, travelInfos, discountCode, partnerCard } = this.props

    return {
      offer_id: offerBuilder.id,
      ...travelInfos,
      discount_code: discountCode ? discountCode.code : null,
      partner_loyalty_number: partnerCard ? partnerCard.cardNumber : null,
      partner: partnerCard ? partnerCard.id : null,
      multiple_entries: this.isMultipleEntriesParamSet(),
      personal_data_com: userConsent,
      price: offerBuilder.amount.bookingAmount,
      begin_date: beginDate.toISOString(true),
      end_date: endDate.toISOString(true),
      renewal_date: renewalDate,
      professional: this.state.professionalUse
    }
  }

  // is used for completeBooking: where to submit data on the main CTA button
  backendPostUrl = () => `${this.props.localePrefix}/purchases/create_react`

  submitButtonMessageId = () => {
    const narrowMobileScreen = window.innerWidth < 300
    if (narrowMobileScreen) return 'authentication.finalize_short'
    if (this.props.type === 'subscription') return 'authentication.finalize_subscription'
    return 'authentication.finalize'
  }

  render () {
    const { user, previousSteps } = this.props
    const { loading } = this.state

    let breadcrumbWrapperClasses = 'registration__breadcrumb-wrapper--centered-on-tablet-summary' +
      ' registration__breadcrumb-wrapper--no-padding registration__breadcrumb-wrapper--min-height'
    if (previousSteps.length > 0) breadcrumbWrapperClasses = breadcrumbWrapperClasses + ' registration__breadcrumb-wrapper--with-stepper'

    return (
      <>
        <div className='purchase-summary__header-left'>
          <div className='registration__logo registration__logo--blue registration__logo--hidden-on-desktop registration__logo--aligned-with-stepper'>
            <SvgImage svg={LogoSvg} />
          </div>
        </div>

        <div className='react-component react-component--flex react-component--no-margin row'>
          <div className='row purchase-summary__header--right purchase-container h-100p'>
            <div className='purchase-summary purchase-summary--real-summary'>
              {this.modalWindows()}

              <div className='row purchase-summary__header'>
                <RegistrationBreadcrumb previousSteps={previousSteps} activeStepId='purchase' futureSteps={[]}
                  stepperClasses='stepper--no-padding'
                  additionalClasses={breadcrumbWrapperClasses}
                  breadcrumbClasses='registration__breadcrumb--at-summary'
                  insideLogoClasses='registration__logo--inside-breadcrumb' />
                <hr className='purchase-summary__divider purchase-summary__divider--no-shadow purchase-summary__divider--starting-left-visible' />
              </div>

              <div className='purchase-summary__page-title row'>
                <h1>
                  <FormattedMessage id={`purchase.purchase_summary.subscription.title`} />
                </h1>
              </div>

              {this.nonPaymentErrorsWithRedirectSection()}

              <div className='row'>
                <div className='purchase-summary__summary-section col-xs-12 col-sm-4 col-sm-push-8'>
                  <div className='row'>
                    <div className='purchase-summary__offer'>
                      <div className='purchase-summary__offer-title'>
                        <FormattedMessage id='purchase.purchase_summary.offer.title' />
                      </div>
                      {this.parkOfferSummary()}

                      {this.reassurancePanel()}
                    </div>
                  </div>
                </div>
                <div className='purchase-summary__editable-section col-xs-12 col-sm-8 col-sm-pull-4'>
                  {this.editableSection({ saveUrl: false })}

                  {this.paymentErrorsSection()}
                </div>
              </div>
            </div>
            {user.billingIdentity &&
              <div className='purchase-summary__button-container' ref={this.footerRef}>
                <hr className='purchase-summary__divider' />

                {this.checkboxesSection('purchase-summary__checkbox--padded-left')}

                <div className='purchase-summary__button-wrapper'>
                  <button
                    onClick={this.handleSubmitPurchase}
                    className='purchase-summary__button btn btn-primary registration__btn-form'
                    type='submit'
                    disabled={loading}
                    data-automation-id='purchase-summary__save-button'
                  >
                    {loading && <Loader overlay white />}
                    <span className={loading ? 'invisible' : ''}>
                      <FormattedMessage id={this.submitButtonMessageId()} />
                    </span>
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  purchase: {
    previousSteps,
    discountCode,
    mandatoryInfos, travelInfos,
    partnerCard,
    offer: { beginDate, endDate, renewalDate, offerBuilder },
    salesTermsPath
  },
  resources: {
    mobilePurchaseTunnel,
    mobilePurchaseTunnelPaymentInside,
    localePrefix,
    termsPath,
    isDefaultHost
  },
  search: { params: { type } },
  user,
  optinOptions: { userConsent, partnerName, optinDisplaying }
}) => {
  const storagePrefix = offerBuilder.id
  const storedTravelInfos = sessionStorage.getItem(`${storagePrefix}-travelInfos`)
  const resultingTravelInfos = storedTravelInfos ? JSON.parse(storedTravelInfos) : travelInfos

  return ({
    mobilePurchaseTunnel,
    mobilePurchaseTunnelPaymentInside,
    isDefaultHost,
    beginDate,
    discountCode,
    salesTermsPath,
    termsPath,
    endDate,
    renewalDate,
    localePrefix,
    mandatoryInfos,
    offerBuilder,
    partnerCard,
    travelInfos: resultingTravelInfos,
    type,
    user,
    userConsent,
    partnerName,
    optinDisplaying,
    previousSteps
  })
}

export default withRouter(injectIntl(connect(mapStateToProps)(Summary)))
